import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import NavBar from '../components/NavBar';
import RubrosLista from '../components/RubrosLista';
import Articulos from '../components/Articulos';
import Footer from '../components/Footer';

const OPTIONS = { dragFree: true, containScroll: true };

function ArticulosPage({ actualizarCarrito }) {
  const [id, setId] = useState('');
  const location = useLocation(); // Obtén la ubicación actual

  useEffect(() => {
    document.title = 'Articulos - Erika';

    const urlParams = new URLSearchParams(location.search);
    const idFromUrl = urlParams.get('id');

    setId(idFromUrl);

    window.scrollTo(0, 0);
  }, [location.search]); // Escucha cambios en location.search

  return (
    <div className="ArticulosPage">
      <NavBar actualizarCarrito={actualizarCarrito} />
      <div className="mt-2">
        <RubrosLista options={OPTIONS} />
      </div>

      <div className="container mt-1">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9">
            <hr className="mt-0" />
          </div>
        </div>
      </div>

      <Articulos id={id} actualizarCarrito={actualizarCarrito} />
      <Footer />
    </div>
  );
}

export default ArticulosPage;
