import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

import { Link } from "react-router-dom"; // Importa el componente Link de react-router-dom
import {
  AddCircleOutline,
  RemoveCircleOutline,
  TrashOutline,
} from "react-ionicons";

const roundToDecimal = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

function CarritoTarjeta(props) {
  const { titulo, imagen, cant, precio, idPro, un } = props;

  const { addItemToCart, subtractItemToCart, removeFromCart } =
    useContext(CartContext);

    const handleAddToCart = (idPro, cantidad) => {
      const roundedCantidad = roundToDecimal(cantidad, 1);
      addItemToCart({ idPro, cantidad: roundedCantidad });
    };

  const handleSubtractToCart = (idPro, cantidad) => {
    const roundedCantidad = roundToDecimal(cantidad, 1);
    subtractItemToCart({ idPro, cantidad: roundedCantidad });
  };

  const handleRemoveFromCart = (idPro) => {
    removeFromCart(idPro); // Llamar a la función del contexto para eliminar el insumo
  };

  return (
    <div className="articuloa">
      <div className="card mb-3 cardArticulo">
        <div className="row g-0">
          <Link className="col-3 parteImg" to={`/articulo?id=${idPro}`} style={{backgroundImage: `url(${imagen})`}}>
          </Link>

          <div className="col-9">
              <div className=" d-flex justify-content-between">
              <Link className="link" to={`/articulo?id=${idPro}`}>

                <h5 className="pt-2 ps-2 m-0">{titulo}</h5>
                </Link>

                <button
                  className="btn btns"
                  type="button"
                  onClick={() => handleRemoveFromCart(idPro)}
                >
                  <TrashOutline
                    className="p-2"
                    color={"#00000"}
                    height="25px"
                    width="25px"
                  />
                </button>
              </div>
            <div className="fw-bold d-flex justify-content-between align-items-center ms-1">
              <div className="d-flex btnSumaReasta">
                <button
                  className="btn btns"
                  type="button"
                  onClick={() => handleSubtractToCart(idPro, un == "c/u" ? 1 : 0.1)}
                >
                  <RemoveCircleOutline
                    color={"#00000"}
                    height="30px"
                    width="30px"
                  />
                </button>
                <input
                  type="text"
                  className="cant cantCarrito text-center fs-4"
                  aria-label="Amount (to the nearest dollar)"
                  value={`${roundToDecimal(cant, 1)} ${un == "c/u" ? "un" : "kg"}`}
                  readOnly
                />
                <button
                  className="btn btns"
                  type="button"
                  onClick={() => handleAddToCart(idPro, un == "c/u" ? 1 : 0.1)}
                >
                  <AddCircleOutline
                    color={"#00000"}
                    height="30px"
                    width="30px"
                  />
                </button>
              </div>
              <h4 className="pe-2 m-0 mt-2 lh-base fw-bold">${(precio * cant).toFixed()}</h4>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarritoTarjeta;
