import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import "../css/ArticulosTarjeta.css";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  TrashOutline,
  CheckmarkCircle,
} from "react-ionicons";

function Articulos(props) {
  const { titulo, imagen, desc, precio, uni, idPro, disabled } = props;
  const { addItemToCart, subtractItemToCart, removeFromCart, cart } =
    useContext(CartContext);

  const [cantidad, setCantidad] = useState(0); // Estado para la cantidad
  const [showInput, setShowInput] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false); // Estado para mostrar el checkmark

  // Verificar si el producto ya está en el carrito al inicio
  useEffect(() => {
    const cartItem = cart.find((item) => item.idPro === idPro);
    if (cartItem) {
      setCantidad(cartItem.cantidad);
      setShowInput(true);
      setShowCheckmark(true);
    }else{
      setCantidad(0);
      setShowInput(false);
      setShowCheckmark(false);
    }
  }, [cart, idPro]);

  const aumentarCantidad = () => {
    setCantidad((prevCantidad) => prevCantidad + 1);
    setShowInput(true);
    setShowCheckmark(true); // Mostrar checkmark al aumentar cantidad
    handleAddToCart(idPro, cantidad + 1); // Añadir al carrito con la nueva cantidad
  };

  const disminuirCantidad = () => {
    if (cantidad > 1) {
      setCantidad((prevCantidad) => prevCantidad - 1);
      handleSubtractToCart(idPro, cantidad - 1); // Restar al carrito con la nueva cantidad
    } else {
      eliminarCantidad(); // Si la cantidad es 1, eliminar del carrito
    }
  };

  const eliminarCantidad = () => {
    setCantidad(0);
    setShowInput(false);
    setShowCheckmark(false);
    handleRemoveFromCart(idPro); // Eliminar del carrito
  };

  const handleAddToCart = (idPro, cantidad) => {
    addItemToCart({ idPro, cantidad: 1 });
  };

  const handleSubtractToCart = (idPro, cantidad) => {
    subtractItemToCart({ idPro, cantidad: 1 });
  };

  const handleRemoveFromCart = (idPro) => {
    removeFromCart(idPro); // Llamar a la función del contexto para eliminar el insumo
  };

  return (
    <div className="articuloa">
    <span className={`sinStock h2 ${disabled ? 'visually' : 'visually-hidden'}`}>Sin stock</span>

      <div className={`card mb-3 cardArticulo ${disabled ? 'disabledArt' : ''}`}>
        <div className="row g-0">
          <Link className="col-4 parteImg" to={`/articulo?id=${idPro}`} style={{backgroundImage: `url(${imagen})`}} />

          <div className="col-8 parteTexto">
            <Link className="link" to={`/articulo?id=${idPro}`}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title pt-2">{titulo}</h5>
                  <CheckmarkCircle
                    className={`pt-1 ${showCheckmark ? "" : "visually-hidden"}`}
                    color={"#25d366"}
                    height="30px"
                    width="30px"
                  />
                </div>
                <p className="card-text">{desc}</p>
              </div>
            </Link>
            <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
              <div className="precio w-100">
                ${precio} {uni}
              </div>

              <div className="d-flex btnSumaResta">
                {showInput && (
                  <>
                    {cantidad > 1 ? (
                      <button
                        className="btn btns"
                        type="button"
                        onClick={disminuirCantidad}
                      >
                        <RemoveCircleOutline
                          color={"#00000"}
                          height="30px"
                          width="30px"
                        />
                      </button>
                    ) : (
                      <button
                        className="btn btns"
                        type="button"
                        onClick={eliminarCantidad}
                      >
                        <TrashOutline
                          color={"#00000"}
                          height="26px"
                          width="26px"
                        />
                      </button>
                    )}
                    <input
                      className="cant text-center"
                      aria-label="Amount (to the nearest dollar)"
                      value={cantidad}
                      readOnly
                    />
                  </>
                )}
                <button
                  className="btn btns"
                  type="button"
                  onClick={aumentarCantidad}
                >
                  <AddCircleOutline
                    color={"#00000"}
                    height="30px"
                    width="30px"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Articulos;
