import React from "react";
import { Link } from "react-router-dom";
import "../css/PublicidadTarjeta.css";

function PublicidadTarjeta(props) {
  const { img } = props;

  return (
    <div className="publicidadConte">
      <div className="link"> 
        <div className="card cardPubli">
          <div className="img-publi">
            <img className="img-publi" src={img}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicidadTarjeta;
