import "../App.css";

import { useEffect } from "react";
import NavBar from "../components/NavBar";
import Rubros from "../components/Rubros";
import Footer from "../components/Footer";

function RubrosPage() {
  useEffect(() => {
    document.title = "Rubros - Erika";

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="RubrosPage">
      <NavBar />

      <Rubros/>

      <Footer/>
      
    </div>
  );
}

export default RubrosPage;
