import React, { useState } from "react";

import { useNavigate  } from "react-router-dom";
import { PersonOutline, LockClosedOutline } from "react-ionicons";
import { BarLoader } from "react-spinners";
import Swal from "sweetalert2";

function Registrarse() {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate ();


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== repeatPassword) {
      setMensaje("*Las contraseñas no coinciden*");
      return;
    }

    const data = {
      usuario: usuario,
      contrasena: password,
    };

    setLoading(true);

    try {
      const response = await fetch(
        "https://api.erikapolleria.com/index.php?route=cliente",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (response.ok) {
        if (result.message === "Cliente creado exitosamente.") {
          Swal.fire({
            icon: "success",
            title: "Registro exitoso",
            confirmButtonText: "Aceptar",
            customClass: {
              title: "my-swal-title",
              confirmButton: "my-swal-confirm-button",
            },
            buttonsStyling: false, // Deshabilita los estilos de los botones por defecto
            showCancelButton: false, // Oculta el botón de cancelar
            allowOutsideClick: false, // Evita que el usuario cierre el modal haciendo clic fuera de él
          }).then((result) => {
            // Verificar si el usuario confirmó (aceptó)
            if (result.isConfirmed) {
              navigate("/iniciarsesion"); // Redirigir a la ruta deseada usando history.push de React Router
            }
          });
          setMensaje(false);
        } else {
          setMensaje("*" + result.message + "*");
        }
      } else {
        setMensaje("*Error en el registro: " + result.message + "*");
      }
    } catch (error) {
      console.error("Error de red:", error);
      setMensaje("*Error de red: " + error.message + "*");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Usuario
          </label>
          <div className="d-flex align-items-center rounded borderErika">
            <PersonOutline className="p-2" />
            <input
              type="text"
              className="form-control inputAut borderErika"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Contraseña
          </label>
          <div className="d-flex align-items-center rounded borderErika">
            <LockClosedOutline className="p-2" />
            <input
              type="password"
              className="form-control inputAut "
              id="exampleInputPassword1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="exampleInputPassword2" className="form-label">
            Repetir contraseña
          </label>
          <div className="d-flex align-items-center rounded borderErika">
            <LockClosedOutline className="p-2" />
            <input
              type="password"
              className="form-control inputAut "
              id="exampleInputPassword2"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-danger w-100 borderErika btnRegis"
          disabled={loading}
        >
          {loading ? <BarLoader loading={loading} /> : "Registrarse"}
        </button>
        <div
          className={`pt-1 text-center txtAlerta ${
            mensaje ? "visible" : "invisible"
          }`}
        >
          &nbsp;
          {mensaje && <span>{mensaje}</span>}
          &nbsp;
        </div>
      </form>
    </div>
  );
}

export default Registrarse;
