import React, { useEffect, useContext, useState } from "react";
import { CartContext } from "../context/CartContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "../css/css-pages/datospage.css";
import NavBarSin from "../components/NavBarSin";

import {
  InformationCircleOutline,
  EllipsisHorizontalCircleOutline,
  CardOutline,
} from "react-ionicons";
import { BarLoader } from "react-spinners";

const roundToDecimal = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

function DatosPage() {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activo, setActivo] = useState("minorista");
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [entrega, setEntrega] = useState(0);

  const [nombreUsuario, setNombreUsuario] = useState("");
  const [userId, setUserId] = useState(null);
  const [clienteData, setClienteData] = useState({
    cod: "",
    usuario: "",
    contrasena: "",
    nombreCompleto: "",
    dni: "",
    telefono: "",
    email: "",
    direccion: "",
    timbre: "",
  });

  useEffect(() => {
    document.title = "Resumen de compra - Erika";
    const nombreGuardado = localStorage.getItem("user");
    const userIdGuardado = localStorage.getItem("userId");

    if (nombreGuardado) {
      setNombreUsuario(nombreGuardado);
    }

    if (userIdGuardado) {
      setUserId(userIdGuardado);
    }

    if (userIdGuardado) {
      fetch(
        `https://api.erikapolleria.com/index.php?route=datoscliente&cod=${userIdGuardado}`
      )
        .then((response) => response.json())
        .then((data) => {
          setClienteData({
            cod: userIdGuardado,
            usuario: data.usuario || "",
            contrasena: data.contrasena || "",
            nombreCompleto: data.nombreCompleto || "",
            dni: data.dni || "",
            telefono: data.telefono || "",
            email: data.email || "",
            direccion: data.direccion || "",
            timbre: data.timbre || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClienteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const actualizarCliente = () => {
    fetch(`https://api.erikapolleria.com/index.php?route=cliente`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clienteData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Actualizar los datos en los inputs
        setClienteData(data);
        // Mostrar un mensaje de éxito
      });
  };

  // ----------------------------------

  const manejarCambio = (opcion, nuevaEntrega) => {
    setActivo(opcion);
    setEntrega(nuevaEntrega);

    // Recalcular el precio total con la nueva entrega
    const nuevoTotal =
      products.reduce((acc, item) => acc + item.precio * item.cantidad, 0) +
      nuevaEntrega;
    setTotalPrice(nuevoTotal);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await Promise.all(
        cart.map(async (item) => {
          const response = await fetch(
            `https://api.erikapolleria.com/index.php?route=producto&cod=${item.idPro}`
          );
          const product = await response.json();

          const precio =
            item.cantidad > 4
              ? product.precioMayorista
              : product.precioMinorista;

          const totalPorInsumo = item.cantidad * precio;

          return {
            ...product,
            cantidad: item.cantidad,
            precio: precio,
            total: totalPorInsumo,
          };
        })
      );
      setProducts(fetchedProducts);

      // Calcular el precio total incluyendo la entrega actual
      const subtotal = fetchedProducts.reduce(
        (acc, item) => acc + item.precio * item.cantidad,
        0
      );

      const total = subtotal + entrega;

      setTotalPrice(total);
    };

    fetchProducts();
  }, [cart, entrega]);

  const formattedTotalPrice = totalPrice.toLocaleString("es-AR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    // Al montar el componente, aplicar estilos al body
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0";

    // Al desmontar el componente, limpiar estilos
    return () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, []);

    // ----------------------------------

  const pagarClick = () => {
    if (
      clienteData.nombreCompleto.trim() === "" ||
      clienteData.dni.trim() === "" ||
      clienteData.telefono.trim() === "" ||
      clienteData.email.trim() === "" ||
      (activo === "mayorista" && clienteData.direccion.trim() === "")
    ) {
      Swal.fire({
        icon: "error",
        title: "Datos incompletos",
        text: "Por favor, complete los datos antes de continuar.",
        confirmButtonText: "Entendido",
        customClass: {
          confirmButton: "my-swal-confirm-button",
        },
        buttonsStyling: false,
      });
      return;
    } else {
      Swal.fire({
        title: "Elegí cómo pagar",
        showDenyButton: true,
        confirmButtonText: "Efectivo",
        denyButtonText: `Mercado Pago`,
        footer: 'Pagando con <strong>Mercado Pago</strong>:<br/> Podes usar tarjetas de <strong>débito, crédito, Visa, MasterCard, y más.</strong>',
        customClass: {
          confirmButton: "my-swal-efect-button",
          denyButton: "my-swal-mp-button",
        },
        buttonsStyling: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          guardarCompra(false);
        } else if (result.isDenied) {
          guardarCompra(true);
        }
      });
    }
  };

  const guardarCompra = (tipo) => {

    actualizarCliente();

    // Obtener el carrito del localStorage
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const userId = JSON.parse(localStorage.getItem("userId")) || [];

    // Preparar los datos de la orden
    const orden = cartItems.map((item) => ({
      codProducto: item.idPro.toString(),
      cantidad: item.cantidad,
      precioUnitario: products.find((p) => p.cod === item.idPro)?.precio || 0,
    }));

    const finalPrice = totalPrice;

    // Preparar los datos para enviar
    const datosCompra = {
      codCliente: userId,
      totalOrden: finalPrice,
      orden: orden,
      created_at: new Date().toLocaleString('es-AR', { 
        timeZone: 'America/Argentina/Buenos_Aires',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }),
    };

    // Realizar el fetch POST
    fetch("https://api.erikapolleria.com/index.php?route=compra", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosCompra),
    })
      .then((response) => response.json())
      .then((data) => {
        if (tipo === true) {
          mercadoPagoBuy(data.compraId, finalPrice, "M");
        } else {
          createPreference(data.compraId, finalPrice, "E");
          navigate(`/?status=approved&external_reference=${data.compraId}`);
        }
      })
      .catch((error) => {
        console.error("Error al realizar la compra:", error);
      });
  };

  const mercadoPagoBuy = async (codCompra, monto, formaDePago) => {
    const result = await createPreference(codCompra, monto, formaDePago);
    if (result && result.init_point) {
      window.location.href = result.init_point; // Redirigir al usuario al init_point
    }
  };

  const createPreference = async (codCompra, monto, formaDePago) => {
    try {
      // Convertir codCompra y monto a enteros
      const codCompraInt = parseInt(codCompra, 10);
      const montoInt = parseInt(monto);

      const response = await fetch(
        "https://api.erikapolleria.com/index.php?route=pago",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            codCompra: codCompraInt,
            monto: montoInt,
            formaDePago: formaDePago === "E" ? "E" : undefined,
            delivery: activo === "mayorista" ? "L" : "T",
          }), 
        }
      );

      if (!response.ok) {
        navigate(`/?status=failure`);
      }

      if(formaDePago === "M") {
        const data = await response.json();
        const { init_point, id } = data;
        return { init_point, id };
      }
      
    } catch (error) {
      console.error("Error creando preferencia:", error);
      navigate(`/?status=failure`);
    }
  };

  return (
    <div className="Datos">
      <NavBarSin />

      <div className="container text-start mt-4">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <h2>Agregá tus datos</h2>
            <form>
              <div className="row">
                <div></div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputName"
                      className="form-label mb-0"
                    >
                      Nombre y apellido <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control inputDatos"
                      id="exampleInputName"
                      name="nombreCompleto"
                      value={clienteData.nombreCompleto}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputDni"
                      className="form-label mb-0"
                    >
                      DNI <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control inputDatos"
                      id="exampleInputDni"
                      aria-describedby="emailHelp"
                      name="dni"
                      value={clienteData.dni}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputTel"
                      className="form-label mb-0"
                    >
                      Teléfono de contacto{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control inputDatos"
                      id="exampleInputTel"
                      name="telefono"
                      value={clienteData.telefono}
                      onChange={handleChange}
                      required
                    />
                    <div id="emailHelp" className="form-text">
                      Número de teléfono para consultas.
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-0"
                    >
                      Correo electrónico <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control inputDatos"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="email"
                      value={clienteData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <hr />

              <h2 className="mt-4">Elegí la forma de entrega</h2>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className={`text-start preciosInsu ${
                      activo === "mayorista" ? "activo" : "desactivado"
                    }`}
                    onClick={() => manejarCambio("mayorista", 1000)}
                  >
                    {clienteData.direccion ? (
                      <div>
                        <h4 className="m-0 p-1 mb-2 border-bottom">
                          Enviar a domicilio{" "}
                        </h4>
                        <span className="p-1 pb-0  text-body-secondary d-flex justify-content-between align-items-center">
                          {clienteData.direccion}
                          <a
                            className="p-0 m-0 ellipsisHover"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <EllipsisHorizontalCircleOutline
                              title={"Editar"}
                              height="30px"
                              width="30px"
                            />
                          </a>
                        </span>
                      </div>
                    ) : (
                      <h4
                        className="m-0 p-1"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Enviar a domicilio
                      </h4>
                    )}
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Agrega tu dirección
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputTel"
                              className="form-label mb-0"
                            >
                              Dirección completa{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control inputDatos"
                              id="exampleInputTel"
                              name="direccion"
                              value={clienteData.direccion}
                              onChange={handleChange}
                              required
                            />
                            <div id="emailHelp" className="form-text">
                              Calle, número y barrio.
                            </div>
                          </div>
                          <p className="text-danger">
                            El costo de entrega mínimo es $1.000, y puede variar
                            según tu dirección. ¡Te avisaremos si hay alguna
                            diferencia!
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Aceptar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`d-flex justify-content-between preciosInsu ${
                      activo === "minorista" ? "activo" : "desactivado"
                    }`}
                    onClick={() => manejarCambio("minorista", 0)}
                  >
                    <h4 className="m-0 p-1">Retirar del local</h4>
                    <h5
                      className="text-body-secondary m-0 pt-2 pe-1"
                      htmlFor="flexRadioDefault2"
                    >
                      Gratis
                    </h5>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-1">
            <hr className="mt-4 d-block d-lg-none" />
          </div>

          <div className="col-md-4">
            <div className="p-4 mt-3 mt-md-0 h-100 resumenDatos rounded borderErika">
              <h3 className="fw-bold">Resumen de compra</h3>
              <hr />
              <div className="">
                <h4>Productos (total)</h4>
                {products.map((insumo) => (
                  <div
                    className="d-flex justify-content-between"
                    key={insumo.cod}
                  >
                    <p className="text-truncate pe-1 m-0">• {insumo.nombre}</p>
                    <h5 className="fw-bold">
                      {`$${roundToDecimal(insumo.total, 1)}`}
                    </h5>
                  </div>
                ))}
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h4 className="position-relative pe-2">
                  Entrega
                  {entrega === 1000 && (
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill "
                      data-tooltip-id="my-tooltip-1"
                    >
                      <InformationCircleOutline
                        color={"#ff0000"}
                        height="18px"
                        width="18px"
                      />
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  )}
                </h4>

                <h5 className="fw-bold">${entrega}</h5>
              </div>

              <ReactTooltip
                id="my-tooltip-1"
                place="bottom"
                variant="error"
                content="Entrega desde $1000, varía según la dirección."
              />

              <hr />

              <div className="d-flex justify-content-between">
                <h4>Pagás</h4>
                <h4 className="fw-bold">${formattedTotalPrice}</h4>
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-start mt-4">
            <button
              className="btn w-100 borderErika btnRegis mt-2 d-flex justify-content-center"
              onClick={pagarClick}
            >
              Pagar
            </button>

          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="text-center text-body-secondary">
          Diseñado por{" "}
          <a href="#" className="perse">
            Persevera
          </a>{" "}
          © 2024 Polleria Erika
        </p>
      </div>
    </div>
  );
}
export default DatosPage;
