import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBarSin from "./NavBarSin";

import {
  CheckmarkCircleOutline,
  CloseCircleOutline,
  DocumentTextOutline,
  StopwatchOutline,
  InformationCircleOutline,
} from "react-ionicons";
import "../css/estadopedido.css";

function EstadoPedido({ estado }) {
  const [ordenData, setOrdenData] = useState([]);

  useEffect(() => {
    const obtenerDatosOrden = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const codOrdenDeCompra = urlParams.get("external_reference");

      console.log("Código de orden de compra:", codOrdenDeCompra);

      if (codOrdenDeCompra) {
        fetch(
          `https://api.erikapolleria.com/index.php?route=orden&codOrdenDeCompra=${codOrdenDeCompra}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data && data.length > 0) {
              setOrdenData(data);
            } else {
              console.log("No se recibieron datos válidos de la API");
            }
          })
          .catch((error) => console.error("Error al obtener los datos:", error));
      } else {
        console.log("No se encontró el código de orden de compra en la URL");
      }
    };

    // Intentar obtener los datos inmediatamente
    obtenerDatosOrden();

    // Si no funciona, intentar de nuevo después de un breve retraso
    const timeoutId = setTimeout(obtenerDatosOrden, 1000);

    window.scrollTo(0, 0);

    // Limpiar el timeout si el componente se desmonta
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <NavBarSin />

      <div className="container d-flex justify-content-center my-4 ">
        {estado === "Aprobado" && (
          <div className="colorEstado bg-success rounded-3 border-success w-100 d-flex justify-content-center">
            <div className="container containerEstado">
              <CheckmarkCircleOutline
                cssClasses={"mt-5 mb-2"}
                color={"#fff"}
                height="125px"
                width="125px"
              />
              <h1 className="pb-2 text-white">¡Gracias por tu compra!</h1>

              <div className="bg-light rounded-3 p-4 p-md-5 borderErika mt-1 mb-3 text-start ">
                {ordenData && ordenData.length > 0 ? (
                  <div>
                    <div className="d-flex">
                      <div className="me-2 mb-0 pb-0">
                        <DocumentTextOutline
                          color={"#00000"}
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <h2 className="text-decoration-underline mb-4 mt-1">
                        Detalles del pedido #{ordenData[0].codOrdenDeCompra}
                      </h2>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-light mb-3">
                        <thead>
                          <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Precio total</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          {ordenData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.nombre}</td>
                              <td>
                                {item.cantidad} {item.unidad}
                              </td>
                              <td>${item.precioUnitario}</td>
                              <td>${item.precioTotal}</td>
                            </tr>
                          ))}
                        </tbody>


                        <tfoot>
                    <tr>
                      <td colSpan="3" className="text-end">
                        Subtotal:
                      </td>
                      <td className="fw-bold">
                        ${ordenData[0].sumaPrecioTotal || "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-end">
                        Delivery:
                      </td>
                      <td className="fw-bold">
                      ${ordenData[0].delivery === "L" ? "1000.00" : "0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-end fw-bold h5">
                        Total del pedido:
                      </td>
                      <td className="fw-bold h5">${ordenData[0].delivery === "L"
                          ? (parseFloat(ordenData[0].sumaPrecioTotal) + 1000).toFixed(2)
                        : parseFloat(ordenData[0].sumaPrecioTotal).toFixed(2)}</td>
                    </tr>
                  </tfoot>
                      </table>
                    </div>
                    
                  </div>
                ) : (
                  <h4>Cargando datos del pedido...</h4>
                )}
              </div>

              <div className="bg-light rounded-3 p-4 p-md-5 borderErika mt-1 mb-3 text-start ">
                <div className="d-flex">
                  <div className="me-2 mb-0 pb-0">
                    <StopwatchOutline
                      color={"#00000"}
                      height="40px"
                      width="40px"
                    />
                  </div>
                  <h2 className="text-decoration-underline mb-4 mt-1">
                    Horarios de entrega/retiro
                  </h2>
                </div>
                <h5 className="text-body-secondary">
                  Lunes a Viernes de 9 a.m. a 1:30 p.m. y de 5:30 a 9:30 p.m.
                </h5>
              </div>

              <div className="bg-light rounded-3 p-4 p-md-5 borderErika mt-1 text-start ">
                <div className="d-flex">
                  <div className="me-2 mb-0 pb-0">
                    <InformationCircleOutline
                      color={"#00000"}
                      height="40px"
                      width="40px"
                    />
                  </div>
                  <h2 className="text-decoration-underline mb-4 mt-1">
                    Aviso: Costo de Entrega Variable
                  </h2>
                </div>
                <h5 className="text-body-secondary">
                  El costo de entrega mínimo es $1.000, y puede variar según tu
                  dirección. ¡Te avisaremos si hay alguna diferencia!{" "}
                </h5>
              </div>

              <div className="d-flex justify-content-center align-content-center align-items-center">
                <Link
                  to="/rubros"
                  className="btn btn-warning borderErika btnRegis mt-4"
                >
                  Seguir comprando
                </Link>
                <Link
                  to="/mispedidos"
                  className="icon-link icon-link-hover ms-4 mt-4"
                >
                  Ir a mis pedidos
                </Link>
              </div>

              <div className="mt-5">
                <p className="text-center text-body-secondary">
                  Diseñado por{" "}
                  <a href="#" className="perse">
                    Persevera
                  </a>{" "}
                  © 2024 Polleria Erika
                </p>
              </div>
            </div>
          </div>
        )}
        {estado === "Rechazado" && (
          <div className="colorEstado bg-danger rounded-3 border-danger w-100 d-flex justify-content-center">
            <div className="container containerEstado">
              <CloseCircleOutline
                cssClasses={"mt-5 mb-2"}
                color={"#fff"}
                height="125px"
                width="125px"
              />
              <h1 className="pb-2 text-white">¡Algo salió mal!</h1>

              <div className="bg-light rounded-3 p-4 p-md-5 borderErika mt-1 mb-3">
                <h2>Tu orden fue rechazada</h2>
                <h5>
                  Por favor, intentá nuevamente o comunicate al número{" "}
                  <a
                    href="https://api.whatsapp.com/send/?phone=543517537208&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    +54 9 3517537208
                  </a>{" "}
                  para más información.
                </h5>
              </div>

              <div className="d-flex justify-content-center align-content-center align-items-center">
                <Link
                  to="/"
                  className="btn btn-warning borderErika btnRegis mt-4"
                >
                  Ir a la tienda
                </Link>
                <Link
                  to="/mispedidos"
                  className="icon-link icon-link-hover ms-4 mt-4"
                >
                  Ir a mis pedidos
                </Link>
              </div>

              <div className="mt-5">
                <p className="text-center text-body-secondary">
                  Diseñado por{" "}
                  <a href="#" className="perse">
                    Persevera
                  </a>{" "}
                  © 2024 Polleria Erika
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EstadoPedido;