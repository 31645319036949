import React from "react";
import ReactDOM from "react-dom/client";

import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { CartProvider } from "./context/CartContext";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyleSheetManager shouldForwardProp={isPropValid}>
    <CartProvider>
      <App />
    </CartProvider>
  </StyleSheetManager>
);

