import React from "react";
import { Link } from "react-router-dom"; // Importa el componente Link de react-router-dom
import "../css/RubrosTarjeta.css";

const RubrosTarjeta = (props) => {
  const {titulo, imagen, id} = props;
  return (
    <div className="rubro">
      <Link className="link" to={`/articulos?id=${id}`}>
        <div className="card cardRubro">
          <div className="img-container">
            <img className="img-container" src={imagen}></img>
          </div>
          <div className="card-body">
            <h3 className="card-title mt-2">{titulo}</h3>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default RubrosTarjeta;
