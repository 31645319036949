import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../context/CartContext";

import "../css/NavBar.css";
import letras from "../img/erikaLetrasSolo-Rojo.webp";

import { CartOutline, PersonOutline, CloseCircleOutline } from "react-ionicons";

import Buscador from "./Buscador";
import Carrito from "./Carrito";
import IconCliente from "./IconCliente";

function NavBar() {
  const { countUniqueProducts } = useContext(CartContext);

  return (
    <div className="cuerpo">
      <div className="container">
        <div className="row">
          <Link to="/rubros" className="col-6 col-md-3 navbarLeft">
            <img src={letras} className="logo" alt="logo" />
          </Link>

          <div className="col-6 order-md-3 col-md-3 justify-content-end navbarRight">
            <IconCliente />

            <a
              className="icon shop-container"
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <CartOutline color={"#00000"} height="40px" width="40px" />
              <span className="indicador translate-middle badge rounded-pill">
                {countUniqueProducts()}
              </span>
            </a>

            <div
              className="offcanvas offcanvas-end offcanvasCarrito"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header pb-0">
                <h3 className="offcanvas-title" id="offcanvasRightLabel">
                  Carrito de compras
                </h3>
                <button
                  type="button"
                  className="btn"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <CloseCircleOutline
                    color={"#00000"}
                    height="30px"
                    width="30px"
                  />{" "}
                </button>
              </div>

              <div className="offcanvas-body pt-2 pb-2 ps-0 pe-0">
                <Carrito />
              </div>
            </div>
          </div>

          <div className="col-12 order-md-2 col-md-6 navbarCenter">
            <Buscador />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
