import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../context/CartContext";

import "../css/Insumo.css";
import {
  AddOutline,
  RemoveOutline,
  CheckmarkCircleOutline,
  CheckmarkCircle,
} from "react-ionicons";
import { BarLoader } from "react-spinners";

const roundToDecimal = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

function Insumo(props) {
  const { titulo, imagen, desc, precioMin, precioMay, uni, idPro, disabled } =
    props;
  const [loading, setLoading] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false); // Estado para mostrar el checkmark

  const [activo, setActivo] = useState("minorista");
  const [cantidad, setCantidad] = useState(activo === "minorista" ? 1 : 5);
  const [precioFinal, setPrecioFinal] = useState(precioMin * cantidad);

  const manejarCambio = (opcion) => {
    setActivo(opcion);
    setCantidad(opcion === "minorista" ? 1 : 5);
  };

  useEffect(() => {
    setPrecioFinal(
      activo === "minorista" ? precioMin * cantidad : precioMay * cantidad
    );
  }, [cantidad, activo, precioMin, precioMay]);

  const restarCantidad = () => {
    if (uni === "c/u") {
      if (cantidad > (activo === "minorista" ? 1 : 5)) {
        setCantidad(cantidad - 1);
      }
    } else {
      if (cantidad > (activo === "minorista" ? 0.1 : 5)) {
        setCantidad(Math.round((cantidad - 0.1) * 10) / 10);
      }
    }
  };

  const sumarCantidad = () => {
    if (uni === "c/u") {
      setCantidad(cantidad + 1);
    } else {
      // Añadimos 0.1 y redondeamos a una precisión de 1 decimal
      setCantidad(Math.round((cantidad + 0.1) * 10) / 10);
    }
  };

  const obtenerUniMostrada = () => {
    return uni === "c/u" ? "un" : uni;
  };

  const { addItemToCart, cart } = useContext(CartContext);

  // Verificar si el producto ya está en el carrito al inicio
  useEffect(() => {
    const cartItem = cart.find((item) => item.idPro === idPro);
    if (cartItem) {
      setShowCheckmark(true);
    } else {
      setShowCheckmark(false);
    }
  }, [cart, idPro]);

  const handleAddToCart = (idPro, cantidad) => {
    setLoading(true);
    addItemToCart({ idPro, cantidad });

    setTimeout(() => {
      setLoading(false);
    }, 500); // Espera de 1 segundo (1000 milisegundos)
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-9">
          <div className="row px-2">
            <div
              className={`col-md-5 container conImgInsu position-relative img-containerInsu ${
                disabled ? "disabledArt" : ""
              }`}
              style={{ backgroundImage: `url(${imagen})` }}
            >
              <CheckmarkCircle
                className={`position-absolute checkInsumo ${
                  showCheckmark ? "" : "visually-hidden"
                }`}
                color={"#25d366"}
                height="40px"
                width="40px"
              />
              <span
                className={`sinStock h2 ${
                  disabled ? "visually" : "visually-hidden"
                }`}
              >
                Sin stock
              </span>
            </div>
            <div
              className={`col-md-7 text-start pt-3 ps-md-3 p-0 ${
                disabled ? "disabledArt" : ""
              }`}
            >
              <h1 className="tituloInsu">{titulo}</h1>
              <p className="textoInsu">{desc}</p>

              <div className="container">
                <div className="row">
                  <div
                    className={`${
                      precioMay == 0 || precioMay == precioMin ? "col-12 pe-0" : "col-6"
                    } ps-0`}
                  >
                    <div
                      className={`preciosInsu ${
                        activo === "minorista" ? "activo" : "desactivado"
                      }`}
                      onClick={() => manejarCambio("minorista")}
                    >
                      <h5>{precioMay == 0 || precioMay == precioMin ? "Precio" : "Minorista"}</h5>
                      <h4 className="fw-bold mb-0">
                        ${precioMin} {uni}
                      </h4>
                    </div>
                  </div>

                  {precioMay == 0 || precioMay == precioMin ? (
                    ""
                  ) : (
                    <div className="col-6 pe-0">
                      <div
                        className={`preciosInsu ${
                          activo === "mayorista" ? "activo" : "desactivado"
                        }`}
                        onClick={() => manejarCambio("mayorista")}
                      >
                        <h5>Mayorista</h5>
                        <h4 className="fw-bold mb-0">
                          ${precioMay} {uni}
                        </h4>
                      </div>
                    </div>
                  )}
                </div>

                <div className="agregarCont row">
                  <p className="totalInsu mb-1">
                    Total: ${roundToDecimal(precioFinal, 1)}
                  </p>

                  <div className="col-5">
                    <div className="input-group sumayresta">
                      <button
                        className="btn btnResta"
                        type="button"
                        id="button-addon1"
                        onClick={restarCantidad}
                      >
                        <RemoveOutline
                          color={"#00000"}
                          height="24px"
                          width="24px"
                        />{" "}
                      </button>
                      <input
                        type="number"
                        className="form-control inputSyR text-end"
                        aria-label="Amount (to the nearest dollar)"
                        value={cantidad}
                        onChange={() => {}}
                        readOnly
                      />
                      <span className="uniInput">{obtenerUniMostrada()}</span>
                      <button
                        className="btn btnSuma"
                        type="button"
                        id="button-addon2"
                        onClick={sumarCantidad}
                      >
                        <AddOutline
                          color={"#00000"}
                          height="25px"
                          width="25px"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="col-7">
                    <button
                      className="btn btn-warning w-100 btnAgregar"
                      onClick={() => handleAddToCart(idPro, cantidad)}
                    >
                      {loading ? (
                        <BarLoader loading={loading} />
                      ) : (
                        "Agregar al carrito"
                      )}
                    </button>
                  </div>
                </div>

                <div className="row mt-2 visually-hidden">
                  <p className="mb-0 ps-0">
                    <CheckmarkCircleOutline
                      color={"#00000"}
                      height="25px"
                      width="25px"
                    />
                    <span className="ps-1">Ya agregaste este producto.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insumo;
