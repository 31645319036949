import React, { useState, useEffect } from "react";
import RubrosTarjeta from "./RubrosTarjeta";
import EmblaCarousel from "./EmblaCarousel";
import "../css/Rubros.css";
import "../css/embla.css";
import Publicidad from "./Publicidad";

const OPTIONS = { dragFree: true };

function Rubros() {
  const [rubros, setRubros] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para indicar si los datos están siendo cargados

  useEffect(() => {
    fetch("https://api.erikapolleria.com/index.php?route=rubro")
      .then((response) => response.json())
      .then((data) => {
        // Ordenar los rubros alfabéticamente por su nombre
        const rubrosOrdenados = data.sort((a, b) =>
          a.rubro.localeCompare(b.rubro)
        );
        setRubros(rubrosOrdenados);
        setLoading(false); // Indicar que los datos han sido cargados
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false); // Indicar que los datos han sido cargados, incluso si hay un error
      });
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-9">
          <h2 className="txtBienv">Hola! ¿Qué vas a pedir hoy?</h2>

          <Publicidad />

          <hr />

          {loading ? (
            // Mostrar el placeholder mientras se cargan los datos
            <div className="row row-cols-2 row-cols-md-3 g-4">
              {[...Array(6)].map((_, index) => (
                <div className="col" key={index}>
                  <div className="card cardRubro">
                    <div className="img-container">
                      <img className="img-container placeholder col-12" />
                    </div>
                    <div className="card-body">
                      <p className="placeholder-glow">
                        <span className="placeholder col-12"></span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : rubros.length > 0 ? (
            <div className="row row-cols-2 row-cols-md-3 g-4">
              {rubros.map((rubro) => (
                <div className="col" key={rubro.cod}>
                  <RubrosTarjeta
                    titulo={rubro.rubro}
                    id={rubro.cod}
                    imagen={rubro.imagen}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="row row-cols-2 row-cols-md-3 g-4">
              {[...Array(6)].map((_, index) => (
                <div className="col" key={index}>
                  <div className="card cardRubro">
                    <div className="img-container">
                      <img className="img-container placeholder col-12" />
                    </div>
                    <div className="card-body">
                      <p className="placeholder-glow">
                        <span className="placeholder col-12"></span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Rubros;
