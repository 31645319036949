import React, { useEffect } from "react";
import "../css/css-pages/autenticacionpage.css";
import erika from "../img/ERIKA.webp";

import { Link, useLocation } from "react-router-dom";
import IniciarSesion from "../components/IniciarSesion";
import Registrarse from "../components/Registrarse";

function AutenticacionPage() {
  const location = useLocation();

  useEffect(() => {
    // Al montar el componente, aplicar estilos al body
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0';

    // Al desmontar el componente, limpiar estilos
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  const renderMessage = () => {
    if (location.pathname === "/iniciarsesion") {
      return (
        <div>
          <h1 className="fw-bold">Bienvenido</h1>
          <p className="text-body-secondary">
            ¿Aún no tienes una cuenta?{" "}
            <Link className="perse" to="/registrarse">
              Regístrate aquí
            </Link>
          </p>
        </div>
      );
    } else if (location.pathname === "/registrarse") {
      return (
        <div>
          <h1 className="fw-bold">Crea tu cuenta</h1>
          <p className="text-body-secondary">
            ¿Ya tienes una cuenta?{" "}
            <Link className="perse" to="/iniciarsesion">
              Inicia sesión aquí
            </Link>
          </p>
        </div>
      );
    }
    return null;
  };
  
  const renderContent = () => {
    if (location.pathname === "/iniciarsesion") {
      return <IniciarSesion />;
    } else if (location.pathname === "/registrarse") {
      return <Registrarse />;
    }
    return null;
  };

  useEffect(() => {
    if (location.pathname === "/iniciarsesion") {
      document.title = "Iniciar sesión - Erika";
    } else if (location.pathname === "/registrarse") {
      document.title = "Registrarse - Erika";
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <div className="conAut d-flex align-items-center">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-5">
              <Link to="/rubros">
                <img className="mt-4 mb-2 w-25" src={erika} alt="Logo Erika" />
              </Link>

              {renderMessage()}

              <div className="card cardAut borderErika p-4 pb-0 shadow">
                <div className="card-body p-2 text-start">
                  {renderContent()}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="text-center text-body-secondary">
                Diseñado por{" "}
                <a href="#" className="perse">
                  Persevera
                </a>{" "}
                © 2024 Polleria Erika
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AutenticacionPage;
