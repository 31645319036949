import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import "./App.css";
import PrivateRoute from "./components/config/PrivateRoute"; // Ajusta la importación según la ubicación de tu archivo

import IndexPage from "./pages/IndexPage";
import RubrosPage from "./pages/RubrosPage";
import ArticulosPage from "./pages/ArticulosPage";
import InsumoPage from "./pages/InsumoPage";
import AutenticacionPage from "./pages/AutenticacionPage"; 
import DatosPage from "./pages/DatosPage";
import ClientePage from "./pages/ClientePage";
import InfoPage from "./pages/InfoPage";
import PedidosPage from "./pages/PedidosPage";

function App() {
  
  return (
      <div className="App">
        <Router>
          <Routes>
          <Route path="/" element={<IndexPage />} />
            <Route path="/iniciarsesion" element={<AutenticacionPage />} />
            <Route path="/registrarse" element={<AutenticacionPage />} />
            <Route path="/informacion" element={<InfoPage />} />

            <Route
              path="/datos"
              element={<PrivateRoute element={DatosPage} />}
            />
            <Route
              path="/cliente"
              element={<PrivateRoute element={ClientePage} />}
            />
                        <Route
              path="/mispedidos"
              element={<PrivateRoute element={PedidosPage} />}
            />

            <Route path="/rubros" element={<RubrosPage />} />
            <Route path="/articulos" element={<ArticulosPage />} />
            <Route path="/articulo" element={<InsumoPage />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
