import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Importa el componente Link y useLocation de react-router-dom
import useEmblaCarousel from "embla-carousel-react";

import "../css/rubroslista.css";

const RubrosLista = (props) => {
  const { options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const [rubros, setRubros] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para indicar si los datos están siendo cargados

  const location = useLocation(); // Obtén la ubicación actual
  const [idFromUrl, setIdFromUrl] = useState(null);

  useEffect(() => {
    if (location.pathname === "/articulos") {
      const urlParams = new URLSearchParams(location.search);
      const idFromUrl = urlParams.get("id");
      setIdFromUrl(idFromUrl);
    }
  }, [location]);

  useEffect(() => {
    fetch("https://api.erikapolleria.com/index.php?route=rubro")
      .then((response) => response.json())
      .then((data) => {
        // Ordenar los rubros alfabéticamente por su nombre
        const rubrosOrdenados = data.sort((a, b) =>
          a.rubro.localeCompare(b.rubro)
        );
        setRubros(rubrosOrdenados);
        setLoading(false); // Indicar que los datos han sido cargados
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false); // Indicar que los datos han sido cargados, incluso si hay un error
      });
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-9">
          <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              {loading ? (
                <div className="embla__container">
                  {[...Array(7)].map((_, index) => (
                    <div className="embla__slide contRubrosList" key={index}>
                      <div className="embla__slide__number cuadrosRL">
                        <a className="cardRL">
                          <img className="imgRL placeholder col-12"></img>
                          <p className="tituloRL pb-2 placeholder-glow placeholderGlow">
                            <span className="placeholder col-6 placeholder-sm"></span>
                          </p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              ) : rubros.length > 0 ? (
                <div className="embla__container">
                  {rubros.map((rubro) => (
                    <div
                      className="embla__slide contRubrosList"
                      key={rubro.cod}
                    >
                      <div className="embla__slide__number cuadrosRL">
                        <Link
                          className="cardRL"
                          to={`/articulos?id=${rubro.cod}`}
                          title={`Rubro: ${rubro.rubro}`}
                        >
                          <img
                            className="imgRL"
                            src={rubro.imagen}
                            alt={rubro.rubro}
                            style={
                              idFromUrl === rubro.cod.toString()
                                ? { transform: "translateY(-4px)" }
                                : {}
                            }
                          ></img>
                          <p
                            className={`tituloRL ${
                              idFromUrl === rubro.cod.toString()
                                ? "fw-bold"
                                : ""
                            }`}
                          >
                            {rubro.rubro}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="embla__container">
                  {[...Array(7)].map((_, index) => (
                    <div className="embla__slide contRubrosList" key={index}>
                      <div className="embla__slide__number cuadrosRL">
                        <a className="cardRL">
                          <img className="imgRL placeholder col-12"></img>
                          <p className="tituloRL pb-2 placeholder-glow placeholderGlow">
                            <span className="placeholder col-6 placeholder-sm"></span>
                          </p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RubrosLista;
