// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => {
  const isAuthenticated = localStorage.getItem('userId');

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to='/iniciarsesion' />
  );
};

export default PrivateRoute;
