import React, { useState, useEffect } from "react";
import "../css/css-pages/insumopage.css";
import { useLocation } from "react-router-dom";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Insumo from "../components/Insumo";
import Articulos from "../components/Articulos";
import RubrosLista from "../components/RubrosLista";

const OPTIONS = { dragFree: true, containScroll: true };

function InsumoPage() {
  const [insumos, setInsumos] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Al montar el componente, aplicar estilos al body
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0';

    // Al desmontar el componente, limpiar estilos
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  useEffect(() => {
    document.title = "Articulo - Erika";

    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get("id");

    if (id) {
      fetch(`https://api.erikapolleria.com/index.php?route=producto&cod=${id}`)
        .then((response) => response.json())
        .then((data) => {
          const insumosOrdenados = Array.isArray(data) ? data : [data];
          setInsumos(insumosOrdenados);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error buscando datos: ", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }, [location.search]);

  return (
    <div className="InsumoPage">
      <NavBar />

      {loading ? (
        <div className="container mt-2">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-md-5 conImgInsu placeholder-glow">
                  <img className="img-containerInsu placeholder col-12"></img>
                </div>
                <div className="col-md-7 cuerpoInsu">
                  <h1 className="tituloInsu placeholder col-6"></h1>
                  <p className="placeholder-glow placeholderGlow">
                    <span className="placeholder col-12 placeholder-sm"></span>
                    <span className="placeholder col-12 placeholder-sm"></span>
                    <span className="placeholder col-6 placeholder-sm"></span>
                  </p>
                  <hr />

                  <div className="container">
                    <div className="row">
                      <div className="col-6 ps-0">
                        <div className="preciosInsu disabled">
                          <span className="placeholder col-6 placeholder-sm"></span>
                          <span className="placeholder col-12 placeholder-sm"></span>
                        </div>
                      </div>

                      <div className="col-6 pe-0">
                        <div className="preciosInsu disabled">
                          <span className="placeholder col-6 placeholder-sm"></span>
                          <span className="placeholder col-12 placeholder-sm"></span>
                        </div>
                      </div>
                    </div>

                    <div className="preciosInsu row">
                      <p className="totalInsu placeholder-glow placeholderGlow">
                        <span className="placeholder col-4 placeholder-sm"></span>
                      </p>
                      <br />
                      <br />
                      <button className="p-2 w-100 btnAgregar placeholder col-8">
                        <span className="placeholder col-12"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : insumos.length > 0 ? (
        <div>
          {insumos.map((insumo) => (
            <div className="mt-2" key={insumo.cod}>
              <Insumo
                titulo={insumo.nombre}
                imagen={insumo.foto}
                desc={insumo.descripcion}
                precioMin={insumo.precioMinorista}
                precioMay={insumo.precioMayorista}
                uni={insumo.unidad}
                idPro={insumo.cod}
                disabled={insumo.estado === "L"}
              />
              <div className="fotterInsu">

                <div className="container mt-2">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-9">
                      <h2 className="mt-4 text-start">Otros productos</h2>
                    </div>
                  </div>
                </div>

                <Articulos id={insumo.codRubroProducto} exe={insumo.cod} cantidad={4} alternateId={1} />
                
                <div className="container mt-2">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-9">
                      <h2 className="mt-4 text-start">Otros rubros</h2>
                    </div>
                  </div>
                </div>

                <RubrosLista options={OPTIONS} />

                <Footer></Footer>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="container mt-2">
            <div className="row justify-content-center">
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-md-5 conImgInsu placeholder-glow">
                    <img className="img-containerInsu placeholder col-12"></img>
                  </div>
                  <div className="col-md-7 cuerpoInsu">
                    <h1 className="tituloInsu placeholder col-6"></h1>
                    <p className="placeholder-glow placeholderGlow">
                      <span className="placeholder col-12 placeholder-sm"></span>
                      <span className="placeholder col-12 placeholder-sm"></span>
                      <span className="placeholder col-6 placeholder-sm"></span>
                    </p>
                    <hr />

                    <div className="container">
                      <div className="row">
                        <div className="col-6 ps-0">
                          <div className="preciosInsu disabled">
                            <span className="placeholder col-6 placeholder-sm"></span>
                            <span className="placeholder col-12 placeholder-sm"></span>
                          </div>
                        </div>

                        <div className="col-6 pe-0">
                          <div className="preciosInsu disabled">
                            <span className="placeholder col-6 placeholder-sm"></span>
                            <span className="placeholder col-12 placeholder-sm"></span>
                          </div>
                        </div>
                      </div>

                      <div className="preciosInsu row">
                        <p className="totalInsu placeholder-glow placeholderGlow">
                          <span className="placeholder col-4 placeholder-sm"></span>
                        </p>
                        <br />
                        <br />
                        <button className="p-2 w-100 btnAgregar placeholder col-8">
                          <span className="placeholder col-12"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InsumoPage;
