import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../css/carrito.css";
import { AlertCircleOutline } from "react-ionicons";

import CarritoTarjeta from "../components/CarritoTarjeta";
import { CartContext } from "../context/CartContext";

function Carrito() {
  const { cart, clearCart } = useContext(CartContext);
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await Promise.all(
        cart.map(async (item) => {
          const response = await fetch(
            `https://api.erikapolleria.com/index.php?route=producto&cod=${item.idPro}`
          );
          const product = await response.json();

          const precio =
            item.cantidad > 4
              ? product.precioMayorista
              : product.precioMinorista;

          return { ...product, cantidad: item.cantidad, precio: precio };
        })
      );
      setProducts(fetchedProducts);

      // Calcular el precio total
      const total = fetchedProducts.reduce(
        (acc, item) => acc + item.precio * item.cantidad,
        0
      );
      setTotalPrice(total);
    };

    fetchProducts();
  }, [cart]);

  const formattedTotalPrice = totalPrice.toLocaleString("es-AR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleClearCart = () => {
    clearCart(); // Llamar a la función del contexto para vaciar el carrito
  };

  return (
    <div className="Carrito h-100">
      {products.length === 0 ? (
        <div className="container h-100 d-flex flex-column justify-content-between">
          <div className="row scrollCarrito">
            <div className="col h-100">
              <div className="container mt-2">
                <p className="vacio">
                  <AlertCircleOutline
                    color={"#00000"}
                    height="25px"
                    width="25px"
                  />
                  <span className="vacioText">
                    El carrito de compras está vacío
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container h-100 d-flex flex-column">
          <div className="row scrollCarrito">
            <div className="col">
              {products.map((insumo) => (
                <CarritoTarjeta
                  key={insumo.cod}
                  titulo={insumo.nombre}
                  imagen={insumo.foto}
                  cant={insumo.cantidad}
                  idPro={insumo.cod}
                  precio={insumo.precio}
                  un={insumo.unidad}
                />
              ))}
            </div>
          </div>
          <div className="row pt-3 precioFinalCarrito">
            <div className="col">
              <div className="d-flex justify-content-between">
                <h2 className="mb-1">Subtotal:</h2>
                <h2 className="mb-1 fw-bold">${formattedTotalPrice}</h2>
              </div>
              <Link
                to={"/datos"}
                className="btn btn-warning w-100 btnAgregar mb-1 link"
              >
                Comprar
              </Link>
              <a className="perse" onClick={handleClearCart}>
                Vaciar carrito
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Carrito;
