import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Search } from "react-ionicons";
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import "../css/buscador.css";

function Buscador() {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://api.erikapolleria.com/index.php?route=busqueda")
      .then((response) => response.json())
      .then((data) => {
        setOptions(
          data.map((busqueda) => ({
            ...busqueda,
            id: busqueda.cod,
            label: `${busqueda.nombre}`,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []); // Solo se ejecuta una vez al montar el componente

  const handleSelect = (selected) => {
    if (selected && selected.length > 0) {
      const selectedId = selected[0].id;
      navigate(`/articulo?id=${selectedId}`);
    }
  };

  const renderMenu = (results, { text }, state) => {
    let index = 0;
    const productsByRubro = {};
    results.forEach((product) => {
      if (!productsByRubro[product.rubro]) {
        productsByRubro[product.rubro] = [];
      }
      productsByRubro[product.rubro].push(product);
    });

    const items = Object.keys(productsByRubro).map((rubro) => (
      <React.Fragment key={rubro}>
        {index !== 0 && <Menu.Divider />}
        <Menu.Header>{rubro}</Menu.Header>
        {productsByRubro[rubro].map((product) => {
          const item = (
            <MenuItem className="text-truncate" key={index} option={product} position={index}>
              <Highlighter search={state.text}>{product.nombre}</Highlighter>
            </MenuItem>
          );
          index++;
          return item;
        })}
      </React.Fragment>
    ));
    return <Menu>{items}</Menu>;
  };

  // Solución alternativa: Forzar la actualización del componente cuando la URL cambie
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [window.location.href]);

  return (
    <div className="buscador" key={key}> {/* Utiliza el key para forzar la actualización del componente */}
      <div className="input-group flex-nowrap buscar">
        <Typeahead
          clearButton
          className="form-control inputBuscar sinColor"
          placeholder="¿Qué estás buscando?"
          id="selections-example"
          onChange={handleSelect}
          options={options}
          labelKey="label"
          renderMenu={renderMenu}
        />

        <div className="input-group-text lupa sinColor">
          <Search color={"#00000"} height="25px" width="25px" />
        </div>
      </div>
    </div>
  );
}

export default Buscador;
