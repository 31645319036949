import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PersonOutline, LockClosedOutline } from "react-ionicons";
import { BarLoader } from "react-spinners";
import { CartContext } from "../context/CartContext";

function IniciarSesion() {
  const { updateUser } = useContext(CartContext);
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Función para obtener parámetros de la URL
    const obtenerParametrosURL = () => {
      const params = new URLSearchParams(window.location.search);
      const usuarioParam = params.get("usuario");
      const contrasenaParam = params.get("contrasena");

      if (usuarioParam && contrasenaParam) {
        setUsuario(usuarioParam);
        setPassword(contrasenaParam);
      }
    };

    obtenerParametrosURL();
  }, []); // Ejecutar solo una vez al cargar el componente

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Utilizar los valores actuales de usuario y password
    const url = `https://api.erikapolleria.com/index.php?route=cliente&usuario=${usuario}&contrasena=${password}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        if (!isNaN(result.cod)) {
          localStorage.setItem("userId", result.cod);
          localStorage.setItem("user", result.usuario);
          updateUser(result.usuario);
          navigate("/rubros");
        } else {
          setMensaje("*" + result.message + "*");
        }
      } else {
        setMensaje("*Error en el registro: " + result.message + "*");
      }
    } catch (error) {
      console.error("Error de red:", error);
      setMensaje("*Error de red: " + error.message + "*");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Usuario
          </label>
          <div className="d-flex align-items-center rounded borderErika">
            <PersonOutline className="p-2" />
            <input
              type="text"
              className="form-control inputAut borderErika"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Contraseña
          </label>
          <div className="d-flex align-items-center rounded borderErika">
            <LockClosedOutline className="p-2" />
            <input
              type="password"
              className="form-control inputAut "
              id="exampleInputPassword1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        {/*
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input borderErika"
            id="exampleCheck1"
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Recuérdame
          </label>
        </div>
        */}
        <button
          type="submit"
          className="btn btn-danger w-100 borderErika btnIni"
          disabled={loading}
        >
          {loading ? <BarLoader loading={loading} /> : "Ingresar"}
        </button>
        <div
          className={`pt-1 text-center txtAlerta ${
            mensaje ? "visible" : "invisible"
          }`}
        >
          &nbsp;
          {mensaje && <span>{mensaje}</span>}
          &nbsp;
        </div>
      </form>
    </div>
  );
}

export default IniciarSesion;
