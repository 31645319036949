import React, { useState, useEffect } from "react";
import "../css/Articulos.css";
import ArticulosTarjeta from "./ArticulosTarjeta";

function Productos({ id, exe, cantidad, alternateId }) {
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(true);

  const fetchProductos = async (fetchId) => {
    try {
      const response = await fetch(
        `https://api.erikapolleria.com/index.php?route=producto&codRubroProducto=${fetchId}`
      );
      const data = await response.json();
      return data.filter((producto) => producto.cod !== exe);
    } catch (error) {
      console.error("Error buscando datos: ", error);
      return [];
    }
  };

  useEffect(() => {
    const getProductos = async () => {
      setLoading(true);
      setShowContent(false);

      let fetchedProductos = await fetchProductos(id);
      if (fetchedProductos.length === 0 && alternateId) {
        fetchedProductos = await fetchProductos(alternateId);
      }

      const productosOrdenados = fetchedProductos.sort((a, b) =>
        a.nombre.localeCompare(b.nombre)
      );
      setProductos(productosOrdenados);
      setLoading(false);
      if (productosOrdenados.length === 0) {
        setShowContent(true);
      }
    };

    if (id) {
      getProductos();
    } else {
      setShowContent(true);
      setLoading(false);
    }
  }, [id, exe, alternateId]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        {showContent && (
          <>
            <h1>Próximamente...</h1>
          </>
        )}
        <div className="col-12 col-md-9">
          {loading ? (
            <div className="row row-cols-1 row-cols-md-2">
              {[...Array(4)].map((_, index) => (
                <div className="col" key={index}>
                  <div className="card mb-3 cardArticulo">
                    <div className="row g-0">
                      <div className="col-4 placeholder-glow">
                        <img className="linkImg placeholder col-12" />
                      </div>
                      <div className="col-8">
                        <div className="card-body">
                          <h5 className="card-title placeholder col-6 mt-2"></h5>
                          <p className="placeholder-glow placeholderGlow">
                            <span className="placeholder col-12 placeholder-sm"></span>
                            <span className="placeholder col-12 placeholder-sm"></span>
                          </p>
                        </div>
                        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
                          <span className="placeholder col-4"></span>
                          <div className="btnSumaResta">
                            <span className="placeholder col-4"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : productos.length > 0 ? (
            <div className="row row-cols-1 row-cols-md-2">
              {productos.slice(0, cantidad).map((producto) => (
                <div className="col" key={producto.cod}>
                  <ArticulosTarjeta
                    titulo={producto.nombre}
                    imagen={producto.foto}
                    desc={producto.descripcion}
                    precio={producto.precioMinorista}
                    uni={producto.unidad}
                    idPro={producto.cod}
                    disabled={producto.estado === "L"}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-md-2">
              {/* Mostrar otros productos de otro id */}
              {productos.slice(0, cantidad).map((producto) => (
                <div className="col" key={producto.cod}>
                  <ArticulosTarjeta
                    titulo={producto.nombre}
                    imagen={producto.foto}
                    desc={producto.descripcion}
                    precio={producto.precioMinorista}
                    uni={producto.unidad}
                    idPro={producto.cod}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Productos;
