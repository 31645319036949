import React from "react";
import NavBar from "../components/NavBar";

function InfoPage() {
  return (
    <div>
      <NavBar />
      <div className="container">
        <h1 className="my-4 fw-bold">Información</h1>
        <p className="lead">
          Erika-App 2024 Version Estable 2.0 © 2024 Persevera. Todos los
          derechos reservados.
        </p>
        <p className="lead">
          Este sistema es realizado exclusivamente para la polleria Erika.
        </p>
        <br />
        <p className="lead">
          <span className="fw-bold text-decoration-underline">
            Advertencia:
          </span>
          {" "} Este programa está protegido por leyes de derechos de autor y
          otros tratados internacionales. La reproducción o distribución ilícita
          de este programa o de cualquier parte del mismo está penada por la ley
          con severas sanciones civiles y penales y será objeto de todas las
          acciones judiciales que correspondan.
        </p>
        <p className="lead">
          Si tienes una duda o problema cotactanos enviando un whatsapp al
          número: <br />{" "}
          <a
            href="https://api.whatsapp.com/send/?phone=543517537208&text&type=phone_number&app_absent=0"
            className="link-primary text-decoration-underline"
          >
            +54 9 3517537208
          </a>
        </p>
      </div>
      <div className="mt-4">
        <p className="text-center text-body-secondary">
          Diseñado por{" "}
          <a href="#" className="perse">
            Persevera
          </a> © 2024 Polleria Erika
        </p>
      </div>
    </div>
  );
}
export default InfoPage;
