import React, { useState, useEffect } from "react";
import EmblaCarousel from "./EmblaCarousel";
import "../css/embla.css";

const OPTIONS = { dragFree: true };

function Publicidad() {
  const [publicidades, setPublicidades] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.erikapolleria.com/index.php?route=publicidad")
      .then((response) => response.json())
      .then((data) => {
        setPublicidades(data);
        setLoading(false); // Indicar que los datos han sido cargados
      })
      .catch((error) => {
        console.error("Error fetching publicidades data: ", error);
        setLoading(false); // Indicar que los datos han sido cargados, incluso si hay un error
      });
  }, []);
  return (
    <div>
      <EmblaCarousel slides={publicidades} options={OPTIONS} />
    </div>
  );
}
export default Publicidad;
